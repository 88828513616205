<template>
  <div class="content">
    <!-- 内容 -->
    <template v-for="(item, i) in data">
      <div :key="i" class="contentArr">
        <div class="contentText">
          <div class="gang"></div>
          <h3>{{ item.title }}</h3>
          <p v-html="item.text"></p>
          <img :src="item.imgSrc" alt="" :class="'img' + i" v-if="item.state" />
          <video :src="item.imgSrc" alt="" class="img1" controls v-else />
        </div>
      </div>
    </template>
    <!-- 内容 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [
        {
          state: false,
          imgSrc: "https://jsxygkj.oss-cn-hangzhou.aliyuncs.com/official_site/勐腊县易武茶叶协会.mp4",
          // imgSrc: require("@/assets/img/勐腊县易武茶叶协会.mp4"),
          title: "西双版纳易武茶叶协会",
          text: "易武——位于西双版纳，是中国普洱茶顶级产区，素有“中国贡茶第一镇”的美誉。易武茶叶协会是在政府和主管部门的领导下从事茶产业服务和品牌管理的社会组织。秀圆果公司与易武茶叶协会就“易武正山茶”区域地理标识品牌的推广建设，对接茶企原产地品牌赋能，以及区域茶产业的发展方面开展合作。",
        },
        {
          state: true,
          imgSrc: require("@/assets/img/teaCaseWeb1.jpg"),
          title: "福鼎市管阳茶业行业协会",
          text: "管阳镇，是福鼎白茶的主要产区之一，秀圆果公司与管阳茶业行业协会就“云中管阳”建设达成战略协议。秀圆果公司提供管阳地区茶产业管理与服务信息系统建设方案，包括区域品牌宣传、NFC防伪溯源、可视化大屏展示等应用场景。",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.content {
  // 偶数列背景颜色
  .contentArr:nth-child(even) {
    background-color: #f4f4f4;
  }
}
@media screen and (max-width: 750px) {
  .content {
    // 内容样式
    .contentArr {
      background: #fff;
      width: 100%;
      overflow: hidden;
      .contentText {
        padding-bottom: 2.8rem;
        width: 15.87rem;
        margin: auto;
        margin-top: 1.6rem;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
        h3 {
          font-size: 0.92rem;
          font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
          font-weight: bold;
          color: #093c7f;
          margin-top: 0.1rem;
        }
        p {
          // text-indent: 0.48rem;
          font-size: 0.72rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #505050;
          line-height: 1.02rem;
        }
        .img1 {
          width: 15.87rem;
          // height: 8.09rem;
          object-fit: contain;
          margin-top: 0.46rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .content {
    // 内容样式
    .contentArr {
      background: #fff;
      width: 19.2rem;
      overflow: hidden;
      .contentText {
        width: 100%;
        float: left;
        position: relative;
        padding-bottom: 2.8rem;
        margin: 1.6rem 0 0 1.69rem;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
        h3 {
          height: 0.84rem;
          font-size: 0.5rem;
          font-family: PingFang SC-Heavy, PingFang SC;
          font-weight: 800;
          color: #093c7f;
          line-height: 0.7rem;
          margin-top: 0.05rem;
        }
        p {
          // text-indent: 0.48rem;
          margin-top: 0.2rem;
          width: 8.71rem;
          font-size: 0.2rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #000000;
          line-height: 0.36rem;
        }
        .img1 {
          width: 6.78rem;
          object-fit: contain;
          position: absolute;
          top: 0.47rem;
          left: 9rem;
        }
        .care {
          height: 0.25rem;
          font-size: 0.18rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #093c7f;
          line-height: 0.21rem;
          margin-top: 0.2rem;
          .careContent {
            margin-bottom: 0.2rem;
          }
        }
      }
    }
  }
}
</style>
